import React from "react"
import { Link as GaLink } from "gatsby"

import AppBar from "@material-ui/core/AppBar"
import { makeStyles } from "@material-ui/core/styles"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"

import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import HomeIcon from "@material-ui/icons/Home"
import GroupIcon from "@material-ui/icons/Group"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import BusinessIcon from "@material-ui/icons/Business"
import ContactPhoneIcon from "@material-ui/icons/ContactPhone"

const HEADER_LINKS = [
  {
    label: "Home",
    href: "/",
    icon: <HomeIcon />,
  },
  {
    label: "Services",
    href: "/services",
    icon: <BusinessCenterIcon />,
  },
  {
    label: "People",
    href: "/people",
    icon: <GroupIcon />,
  },
  {
    label: "About",
    href: "/about",
    icon: <BusinessIcon />,
  },
  {
    label: "Contact",
    href: "/contact",
    icon: <ContactPhoneIcon />,
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
    width: "40px",
    height: "40px",
  },
  title: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  linksRoot: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },

  mobileMenu: {
    display: "flex",
    color: "white",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  desktopMenu: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
}))

function HideOnScroll(props) {
  const { children } = props

  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = props => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerOpen(open)
  }

  const mobileMenuList = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {HEADER_LINKS.map(link => (
          <ListItem button key={link.label}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText>
              <MuiLink
                component={React.forwardRef((props, ref) => (
                  <GaLink innerRef={ref} {...props} />
                ))}
                color="inherit"
                key={link.href}
                to={link.href}
              >
                {link.label}
              </MuiLink>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <React.Fragment>
              <IconButton
                edge="start"
                aria-label="open drawer"
                color="inherit"
                onClick={toggleDrawer(true)}
                className={classes.mobileMenu}
              >
                <MenuIcon />
              </IconButton>

              <SwipeableDrawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {mobileMenuList}
              </SwipeableDrawer>
            </React.Fragment>

            <MuiLink
              component={React.forwardRef((props, ref) => (
                <GaLink innerRef={ref} {...props} />
              ))}
              color="inherit"
              to="/"
              className={classes.title}
            >
              <Typography component="h4" variant="h6" color="inherit" noWrap>
                Janice Vu & Associates
              </Typography>
            </MuiLink>

            <div className={classes.desktopMenu}>
              <Typography className={classes.linksRoot}>
                {HEADER_LINKS.map(link => (
                  <MuiLink
                    component={React.forwardRef((props, ref) => (
                      <GaLink innerRef={ref} {...props} />
                    ))}
                    color="inherit"
                    key={link.href}
                    to={link.href}
                  >
                    {link.label}
                  </MuiLink>
                ))}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  )
}

export default Header
