import React from "react"
import { Link as GaLink } from "gatsby"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import MuiLink from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"

import Grid from "@material-ui/core/Grid"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CallIcon from "@material-ui/icons/Call"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    color: "white",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 0),
    },
  },
  gridContainer: {
    display: "grid",
    gridGap: theme.spacing(3),
    margin: `${theme.spacing(2)}px 0`,
    justifyContent: "center",
    alignItems: "center",
    gridAutoFlow: "column",
  },
  icon: {
    width: "32px",
    height: "32px",
  },
  parentGridContainer: {
    display: "grid",
    gridGap: theme.spacing(1),
    gridAutoFlow: "column",
    alignItems: "flex-start",
    margin: `${theme.spacing(5)}px 0`,

    [theme.breakpoints.down("xs")]: {
      gridGap: theme.spacing(2),
      gridAutoFlow: "row",
      gridTemplateColumns: "1fr",
    },
  },
  contactDetail: {
    margin: theme.spacing(4, 0),

    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 0),
    },
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
}))

const Copyright = () => {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <MuiLink
        color="secondary"
        component={React.forwardRef((props, ref) => (
          <GaLink innerRef={ref} {...props} />
        ))}
        to="/"
      >
        Janice Vu & Associates
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."} - All rights reserved.
    </Typography>
  )
}

const EXPLORELINKS = {
  heading: "EXPLORE",
  links: [
    {
      href: "/",
      label: "Home",
    },
    {
      href: "/services",
      label: "Services",
    },
    {
      href: "/people",
      label: "People",
    },
    {
      href: "/about",
      label: "About",
    },
  ],
  ariaLabel: "footer explore links",
}

const SERVICESLINKS = {
  heading: "SERVICES",
  links: [
    {
      href: "/services/conveyancing",
      label: "Conveyancing and Property Matters",
    },
    {
      href: "/services/commercial-matters",
      label: "Commercial Matters",
    },
    {
      href: "/services/family-law",
      label: "Family Law",
    },
    {
      href: "/services/immigration-law",
      label: "Immigration Law",
    },
    {
      href: "/services/notary-public",
      label: "Notary Public",
    },
    {
      href: "/services/wills-probate",
      label: "Wills and Probate",
    },
  ],
  ariaLabel: "footer services links",
}

const FooterLinks = ({ heading, links, ariaLabel }) => {
  return (
    <List aria-label={ariaLabel}>
      <ListItem>
        <Typography component="h4" variant="h6" color="inherit" gutterBottom>
          {heading}
        </Typography>
      </ListItem>

      {links.map(link => (
        <ListItem key={link.label}>
          <MuiLink
            component={React.forwardRef((props, ref) => (
              <GaLink innerRef={ref} {...props} />
            ))}
            color="inherit"
            to={link.href}
          >
            <Typography
              component="h5"
              variant="subtitle2"
              color="inherit"
              gutterBottom
            >
              {link.label}
            </Typography>
          </MuiLink>
        </ListItem>
      ))}
    </List>
  )
}

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <div className={classes.parentGridContainer}>
          <div>
            <List aria-label="Footer contact us">
              <ListItem>
                <Typography component="h4" variant="h5" color="inherit" noWrap>
                  Janice Vu & Associates
                </Typography>
              </ListItem>
              <ListItem>
                <Typography component="p" color="inherit">
                  Need a professional advice? Contact us for confidential
                  discussion.
                </Typography>
              </ListItem>

              <ListItem>
                <div className={classes.contactDetail}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <div className={classes.iconWrap}>
                        <MailOutlineIcon fontSize="small" />
                      </div>
                    </Grid>
                    <Grid item>
                      <MuiLink
                        href="mailto:janicevusolicitor@gmail.com"
                        variant="body1"
                        color="secondary"
                      >
                        janicevusolicitor@gmail.com
                      </MuiLink>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item>
                      <div className={classes.iconWrap}>
                        <CallIcon fontSize="small" />
                      </div>
                    </Grid>
                    <Grid item>
                      <MuiLink
                        href="tel:(02) 9727 5459"
                        variant="body1"
                        color="secondary"
                      >
                        (02) 9727 5459
                      </MuiLink>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item>
                      <div className={classes.iconWrap}>
                        <LocationOnIcon fontSize="small" />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        1/119a John Street Cabramatta, NSW 2166
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </ListItem>
            </List>
          </div>

          <div>
            <FooterLinks {...EXPLORELINKS} />
          </div>
          <div>
            <FooterLinks {...SERVICESLINKS} />
          </div>
        </div>
      </Container>
      <Copyright />
    </footer>
  )
}

export default Footer
