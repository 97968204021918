import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  responsiveFontSizes,
} from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"

import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Fab from "@material-ui/core/Fab"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import Zoom from "@material-ui/core/Zoom"

import Header from "../components/header"
import Footer from "../components/footer"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#344955",
    },
    secondary: {
      main: "#f9aa33",
    },
  },
  // below setting has better performance
  // typography: {
  //   fontFamily: [
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(","),
  // },
})

theme = responsiveFontSizes(theme)

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  wrap: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    "-webkit-overflow-scrolling": "touch",
  },

  content: {
    flexGrow: 1,
  },
}))

function ScrollTop(props) {
  const { children } = props
  const classes = useStyles()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

const Layout = props => {
  const classes = useStyles()

  return (
    <div className={classes.wrap}>
      <ThemeProvider theme={theme}>
        <div className={classes.content}>
          <CssBaseline />
          <Header />
          <Toolbar id="back-to-top-anchor" />
          {props.children}
        </div>
        <Footer />
        <ScrollTop {...props}>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </ThemeProvider>
    </div>
  )
}

export default Layout
